import React from 'react'
import "./tilingCard.css"

function TilingCard({ title, description, image }) {
  return (
    <div className='grid'>
        <div className='card'>
            <img src={image} alt="card for expertise" className='w-10 ml-8 mb-3'/>
            <h3 className='ml-8'>{title}</h3>
            <p className='ml-8 mr-8 mb-6'>{description}</p>
            <div class="shine"></div>
            <div className='background'>
                <div className='tiles'>
                    <div className="tile tile-1"></div>
                    <div className="tile tile-2"></div>
                    <div className="tile tile-3"></div>
                    <div className="tile tile-4"></div>

                    <div className="tile tile-5"></div>
                    <div className="tile tile-6"></div>
                    <div className="tile tile-7"></div>
                    <div className="tile tile-8"></div>

                    <div className="tile tile-9"></div>
                    <div className="tile tile-10"></div>
                </div>

                <div className="line line-1"></div>
                <div className="line line-2"></div>
                <div className="line line-3"></div>
            </div>
        </div>
    </div>
  )
}

export default TilingCard