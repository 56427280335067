import React from 'react'
import { Link } from 'react-router-dom'

function Button({ text, nav, color }) {
  return (
    <div className='flex justify-center mb-10'>
        <Link to={nav} className="text-center">
          <button className={`${color} w-28 pt-3 pb-3 text-white hover:text-[#5E81AC] hover:border-[#5E81AC] font-bold border-2 border-[#D8DEE9] rounded-lg transition-all duration-200`}>
            {text}
          </button>
        </Link>
    </div>
  )
}

export default Button
