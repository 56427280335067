import React from 'react'
import "../section/navbar.css";
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <div className='navbar flex items-center justify-between pr-10 pl-10 h-20'>
      <Link className='ghs-link text-[#8aadf4] text-3xl' href='/'>GHS</Link>
      <div className='flex gap-5 text-[#cad3f5] text-lg'>
        <Link to='/about' className='hover:text-[#8aadf4]'>ABOUT</Link>
        <Link to='/work' className='hover:text-[#8aadf4]'>WORK</Link>
        {/* <a href="#contact" className='hover:text-[#BF616A]'>CONTACT</a> */}
        <Link to="/contact" className='hover:text-[#8aadf4]'>CONTACT</Link>
      </div>
    </div>
  );
}

export default Navbar;