import React from 'react';
import "../section/about.css";

function About() {
  return (
    <div className="flex items-center justify-center h-screen bg-[#24273a]">
      <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
      <div className='pl-32 bg-[#24273a] p-8'>
        <h1 className='text-[#ECEFF4] text-3xl mb-8'>Overview</h1>
        
        <div className='flex gap-10 mb-8'>
          <h2 className='text-[#cad3f5] text-2xl w-64'>Programming Languages</h2>
          <div>
            <p className='text-[#cad3f5] mb-2'>JavaScript / Typescript</p>
            <p className='text-[#cad3f5] mb-2'>Python</p>
            <p className='text-[#cad3f5] mb-2'>Java</p>
            <p className='text-[#cad3f5] mb-2'>Go</p>
          </div>
        </div>
        
        <div className='flex gap-10 mb-8'>
          <h2 className='text-[#cad3f5] text-2xl w-64'>Front-end</h2>
          <div>
            <p className='text-[#cad3f5] mb-2'>CSS</p>
            <p className='text-[#cad3f5] mb-2'>HTML5</p>
            <p className='text-[#cad3f5] mb-2'>Tailwind CSS</p>
            <p className='text-[#cad3f5] mb-2'>React</p>
          </div>
        </div>
        
        <div className='flex gap-10 mb-8'>
          <h2 className='text-[#cad3f5] text-2xl w-64'>Back-end</h2>
          <div>
            <p className='text-[#cad3f5] mb-2'>Node.js</p>
            <p className='text-[#cad3f5] mb-2'>Express.js</p>
            <p className='text-[#cad3f5] mb-2'>Spring</p>
            <p className='text-[#cad3f5] mb-2'>MySQL / SQLite</p>
          </div>
        </div>
        
        <div className='flex gap-10 mb-8'>
          <h2 className='text-[#cad3f5] text-2xl w-64'>Other tech skills</h2>
          <div>
            <p className='text-[#cad3f5] mb-2'>Linux</p>
            <p className='text-[#cad3f5] mb-2'>Git</p>
            <p className='text-[#cad3f5] mb-2'>Vim</p>
            <p className='text-[#cad3f5] mb-2'>Figma</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
