import React from 'react'
import "./card.css"

function Card({ name, description, link, tag }) {
  return (
    <div 
      className={`card ${tag === "todo" ? "todo" : ""} ${tag === "b2b" ? "b2b" : ""} ${tag === "recepten" ? "recepten" : ""}`}
    >
        <div className="card-content">
            <h2 className="card-title">{name}</h2>
            <p className="card-body">
              {description}
            </p>
            <a href={link} className="button" target='_blank' rel="noopener noreferrer">
                View project
            </a>
        </div>
    </div>
  )
}

export default Card
