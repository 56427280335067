import React from 'react'
import Hero from './section/Hero'
import Overview from './section/Overview'
import Projects from './section/Projects'
import Button from './components/Button'

function Home() {
  return (
    <div className="bg-[#24273a]">
      <div className="flex flex-col h-screen">
        <Hero />
      </div>
      <div id="overview">
        <Overview />
      </div>
      <div id="projects">
        <Projects />
      </div>
      <div className="flex flex-col items-center justify-center p-8">
        <h2 className='text-[#ECEFF4] text-5xl'>Get in Touch</h2>
        <p className='mt-8 text-[#ECEFF4] mb-8'>Interested in working together?</p>
        <Button text="Let's talk" nav="/contact" color="bg-[#24273a]" />
      </div>
    </div>
  )
}

export default Home
