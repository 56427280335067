import React, { useState } from 'react';
import "../section/hero.css";

function Hero() {
    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    };
    
    const handleMouseLeave = () => {
        setHovered(false);
    };

    return (
        <div className='hero-container'>
            <div className='flex-container flex items-center justify-center'>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div className='flex-text flex-grow flex flex-col name items-start text-center px-6'>
                    <p className='text-[#cdd6f4] text-7xl'>Hi, I'm</p>
                    <p 
                        className={`text-7xl cursor-default transition-all duration-500 ${hovered ? 'text-[#8aadf4] opacity-100' : 'text-[#8aadf4] opacity-75'}`}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        >
                        Nicolas {hovered ? 'Ghyselincks' : 'GHS'}
                    </p>
                    <p className='text-[#cdd6f4] mt-6 text-lg'>
                        a passionate <strong className='text-[#ed8796]'>software developer</strong> with a deep love for technology and a wide range of interests.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Hero;
