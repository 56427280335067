import React from 'react'
import Card from '../components/Card'
import Button from '../components/Button'

function Projects() {
  return (
    <div className='bg-[#24273a] flex flex-col items-center'>
        <h1 className='text-[#ECEFF4] text-5xl'>Projects</h1>
        <p className='mt-8 text-[#ECEFF4]'>Here's a overview of projects  I've worked on.</p>
        <div className='flex flex-wrap justify-center gap-8 mt-8 pb-16'>
            <Card 
              name="Just Do It - Todo app" 
              description="A webserver application developed using Express.js, designed to help you manage your tasks efficiently. This server offers a set of features including task addition, updates, deletion, and categorization, along with a secure login and registration system." 
              link="https://github.com/pgmgent-pgm-3/opdracht-1-to-do-application-NicolasGHS"
              image="../assets/todo.png"
              tag="todo"
            />
            <Card 
              name="B2B Platform"
              description="Web-based e-commerce platform made for Delaware for ordering and managing products in bulk." 
              link="https://github.com/HoGentProjectenII/2023-frontend-gent07"
              image="../assets/b2b.png" 
              tag="b2b"
            />
            <Card 
              name="Receptenweb"
              description="A recipe management application that makes it easy to browse, view, and manage recipes. Users can see a clear list of recipes and access detailed information for each one. The app allows users to add new recipes, edit existing ones, and delete recipes as needed. I built my own API for this project, along with the backend, to handle all the data management. The frontend was developed using PicoCSS."
              link="https://github.com/pgmgent-pgm-2/opdracht-1-receptweb-NicolasGHS"
              image="../assets/receptenweb.png"
              tag="recepten" 
            />
        </div>
        <Button text="See all" nav="/work" color="bg-[#24273a]"/>
    </div>
  )
}

export default Projects