import './App.css';
import Contact from './section/Contact';
import Home from './Home';
import Root from './layouts/Root';
import About from './section/About';
import Work from './pages/Work';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter(createRoutesFromElements(
  <>
    <Route path="/" element={<Root />}>
      <Route path="/" element={<Home />} />
      <Route path="/work" element={<Work />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About />} />
    </Route>
  </>
	
	));

function App() {
  return (
    <div className="bg-[#2E3440]">
      <RouterProvider router={router} />
      {/* <Home /> */}
    </div>
  );
}

export default App;
