import { useState, useRef } from "react";
import "../section/contact.css";

const Contact = () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // prevents page reloading
    setLoading(true); // sets loading to true

    const formUrl =
      "https://docs.google.com/forms/u/0/d/e/1FAIpQLSea8al_tUz7aiD8ZeOZmW57ZEAUz2XwMKye3KesHT7w4QmUUg/formResponse";

    const formData = new FormData();
    // entry ids for the google form
    formData.append("entry.2005620554", form.name);
    formData.append("entry.1045781291", form.email);
    formData.append("entry.839337160", form.message);

    // sends the form data to the google form
    try {
      await fetch(formUrl, {
        method: "POST",
        mode: "no-cors",
        body: formData, // this is the form data
      });

      setLoading(false);
      // resets the form after submission
      setForm({
        name: "",
        email: "",
        message: "",
      });
      alert("Your message has been sent successfully!");
    } catch (error) {
      console.error("Error submitting the form:", error);
      setLoading(false);
      alert("There was an error sending your message.");
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-[#24273a]">
      {/* Container with centered content */}
      <div className="relative flex flex-col items-center gap-10 p-8 bg-[#24273a] rounded-lg shadow-md">
        {/* Animated lines */}
        
        {/* Title */}
        <h3 className="text-3xl text-white z-10 mt-3">Let's Talk!</h3>

        {/* Form */}
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="mt-12 flex flex-col gap-8 z-10"
        >
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">Your name</span>
            <input
              type="text"
              name="name"
              value={form.name}
              onChange={handleChange}
              placeholder="What's your name?"
              className="bg-tertiary py-4 px-6 placeholder:text-[#4C566A] text-black rounded-lg outline-none border-none font-medium"
              required
            />
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">Your e-mail</span>
            <input
              type="email"
              name="email"
              value={form.email}
              onChange={handleChange}
              placeholder="What's your email?"
              className="bg-tertiary py-4 px-6 placeholder:text-[#4C566A] text-black rounded-lg outline-none border-none font-medium"
              required
            />
          </label>
          <label className="flex flex-col">
            <span className="text-white font-medium mb-4">Your message</span>
            <textarea
              rows={7}
              name="message"
              value={form.message}
              onChange={handleChange}
              placeholder="What's your message?"
              className="bg-tertiary py-4 px-6 placeholder:text-[#4C566A] text-black rounded-lg outline-none border-none font-medium"
              required
            />
          </label>
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-[#2E3440] w-28 pt-3 pb-3 text-white hover:bg-[#ECEFF4] hover:text-[#2E3440] font-bold border-[#3B4252] border-4 rounded-lg transition-all duration-200"
            >
              {loading ? "Sending..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
