import React from 'react';
import TilingCard from '../components/TilingCard';
import Button from '../components/Button';
import "./overview.css";

function Overview() {
  return (
    <div className='overview-container pl-32 pr-32 pb-32'>
      <h1 className='text-[#cad3f5] text-5xl'>Overview</h1>
      <p className='mt-8 text-[#cad3f5] pl-16 pr-8 mb-8'>I am a creative developer and a lifelong learner based in Belgium. I have developed my skills through a combination of formal education and self-study. In my free time, I enjoy expanding my knowledge of linux, web development and artificial intelligence.</p>
      <div className='flex flex-wrap justify-center mb-10 gap-10'>
        <TilingCard 
          image="../../assets/code-solid.svg"
          title="Cross-Platform Development" 
          description="Using React, React Native, and Tauri, I craft versatile, cross-platform applications that run seamlessly on web, mobile, and desktop platforms. Whether you're targeting Android, iOS, or desktop environments, I ensure a consistent and engaging user experience across all devices from contept to launch."   
        />
        <TilingCard
          image="../../assets/pen-nib-solid.svg"
          title="User Interface Design"
          description="Using cutting-edge design principles, I create intuitive, visually appealing user interfaces for your product."
        />
        <TilingCard
          image="../../assets/laptop-code-solid.svg"
          title="Machine Learning"
          description="Harnessing the power of machine learning, I develop intelligent, data-driven applications that transform complex data into actionable insights. From initial data exploration to final deployment, your machine learning journey is crafted to meet your unique needs."
        />
        <TilingCard 
          image="../../assets/database-solid.svg"
          title="Back-end Development" 
          description="Specializing in API and back-end development, I build secure, scalable, and high-performance server-side solutions that power your applications."
        />
      </div>
      <div className='flex justify-center mb-10'>
        <Button text="See more" nav="/about" color="bg-[#24273a]"/>
      </div>
      <div>
        <h2 className='text-[#cad3f5] text-2xl mt-6'>My Socials</h2> 
        <div className='flex flex-col'>
          <div className='flex gap-2 mb-3 mt-4 hover:bg-[#494d64] w-36 rounded-md pl-2'>
            <img src="../../assets/github.svg" alt="" className='w-4' />
            <a href='https://github.com/NicolasGHS' target='_blank' rel="noopener noreferrer" className='text-[#8aadf4]'>@NicolasGHS</a>
          </div>
          <div className='flex gap-2 hover:bg-[#494d64] w-52 rounded-md pl-2'>
            <img src="../../assets/linkedin.svg" alt='' className='w-4' />
            <a href='https://www.linkedin.com/in/nicolas-ghyselincks-589105274/' target='_blank' rel="noopener noreferrer" className='text-[#8aadf4]'>@Nicolas_Ghyselincks</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
